<!--Incidencias -->

<template>
  <div class="incidencias">   
    <v-dialog v-model="dialog" content-class="modal" persistent> 
      <template v-slot:activator="{on}">
        
        <!-- Botón para abrir ventana de incidencias -->
        <v-btn 
          v-on='on'
          v-bind="$cfg.btra_cfg.standard"         
          v-if="show"
          title="Envio incidencias"
          :disabled="!show">
            <v-icon dark>{{ "mdi-alert" }}</v-icon>
        </v-btn>       
      </template>

      <div class="contenedor" v-if="schema">
        <v-sheet :elevation="4">

          <!-- Cabecera -->
          <div class="cabecera">
            <base_Header
              v-if="Entorno.header.header"       
              :Entorno="Entorno.header"
              @onEvent="dialog=false">
            </base_Header>
          </div> 
          
          <!-- Botoneras -->
          <div class="conflex pl-2 pt-2">
            <v-btn 
              v-bind="$cfg.btra_cfg.standard"    
              title="Envio incidencia"       
              @click="envio_incidencia()">
                <v-icon>{{'mdi-send-outline'}}</v-icon>
            </v-btn> 
          </div>

          <!-- Controles del Mto -->
          <div class="conflex contenedor"> 
            <div style="width:700px">
              <vllabel
                style="width:200px" 
                label="Control"
                :txt="accionRow.control">              
              </vllabel>

              <vllabel
                style="flex: 0 0 100%" 
                label="Cia"
                :txt="accionRow.name_callemp">              
              </vllabel>

              <v-select                  
                v-bind="$select"
                v-model="schema.ctrls.tipo_incidencia.value"
                :label="schema.ctrls.tipo_incidencia.label"
                :items="itemsIncidencias"
                item-value="id"
                item-text="name"
                return-object
                @change="changeTipo">                
              </v-select>

              <v-textarea
                v-bind="$textarea"
                v-model="schema.ctrls.txt.value"
                :label="schema.ctrls.txt.label"
                no-resize>                
              </v-textarea>
            </div>
          </div>
        </v-sheet> 
      </div>
    </v-dialog>    
  </div>
</template>



<script>

  import { mixinCtrls } from "@/mixins/mixinCtrls.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
      
  export default {
    mixins: [mixinCtrls],
    components: { base_Header },
    props: {
      padre: { type:String, default: '' }, 
      accion: { type:[Number, String], default: ''},
      accionRow: { type:[Object, Array], default: ()=> {}}, 
      show: { type: [String, Boolean], default: "1" },    
      componenteTipo: { type:String, default:'M' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema: {
          ctrls: {
            tipo_incidencia: { f:'tipo_incidencia', label:"Tipo incidencia", value:"0" },
            txt: { f:'txt', label:"", value:"" },
          }
        },

        api:'',
        stName:'stMincidencias',

        dialog:false,
        itemsIncidencias: []
      };
    },


    created() {
      this.ini_data();
    },
   

    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");
       
        // configuro Entorno 
        this.Entorno.header.titulo = "Incidencias Expedientes"; 
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo['F']));
        this.Entorno.btra.footer.extra = [
          { accion:'guardar', btn: "btn_4" }
        ];


        // items adicionales
        // array de incidencias
        this.itemsIncidencias= this.$store.state.datos_iniciales.tipo_incidencias;
        this.itemsIncidencias.unshift({ id:"0", name:"Selecciona incidencia"});
      },


      // envio datos de la incidencia a Mapfre
      async envio_incidencia() {   
        let args = { tipo:'fnc', accion: 'expediente', fn_args: { 
          accion:'incidencias',          
          id: this.accionRow.id,
          msg: this.schema.ctrls.txt.value,
          usu: this.usu
        }};
        console.log('args guardar: ', args);
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        console.log('apiResult guardar: ', apiResult);        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
        
        this.$root.$alert.open('Incidencia enviada correctamene', 'success');
        this.dialog= false;
      },


      // entra al cambiar el tipo de incidencia
      changeTipo(item) {
        this.schema.ctrls.txt.value= item.txt;
      }

    }
  };
</script>
